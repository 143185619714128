body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background: #f4f4f4;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 100px 0 50px 0;
    text-align: center;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    color: #fff;
}

.about-container h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.about-container p {
    font-size: 1.2em;
    line-height: 1.6;
    max-width: 800px;
    margin-bottom: 20px;
}

.about-container .mission,
.about-container .vision,
.about-container .values {
    margin-bottom: 30px;
}

.about-container .mission h2,
.about-container .vision h2,
.about-container .values h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

.about-container .values ul {
    list-style-type: none;
    padding: 0;
}

.about-container .values ul li {
    font-size: 1.1em;
}