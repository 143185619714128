html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.services-container {
    padding: 100px 0 50px;
    background: url('../assets/img/heroSection-bg.webp') no-repeat center center/cover;
    text-align: center;
    color: white;
    min-height: 100vh; /* Yüksekliği tam olarak ayarla */
    box-sizing: border-box; /* Padding ve border'ları da yüksekliğe dahil et */
}

.services-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service {
    background: rgba(255, 255, 255, 0.8); /* Kartların arka planını yarı saydam yap */
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    transition: transform 0.3s ease;
    color: black; /* Kart içindeki metin rengini siyah yap */
}

.service:hover {
    transform: scale(1.05);
}

.service h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
}
