.admin-dashboard {
    padding: 100px 20px;
    background: #f9f9f9;
    min-height: 100vh;
}

.admin-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.admin-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 250px;
    text-align: center;
}

.admin-section h2 {
    margin-bottom: 20px;
}

.admin-section ul {
    list-style: none;
    padding: 0;
}

.admin-section li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
