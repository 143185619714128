.boosting-container {
    padding: 100px 0 50px;
    background: #f0f0f0;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    text-align: center;
}

.boosting-container h1 {
    font-size: 2rem;
    color: #f9f9f9; /* İsteğe bağlı olarak yazı rengini değiştirebilirsiniz */
}

.boosting-selection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.boosting-group {
    background: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 45%;
}

.boosting-group.full-width {
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group select,
.form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.price-display {
    text-align: center;
    margin: 20px 0;
    color: #fff;
}

.submit-button-container {
    text-align: center;
}

.btn {
    background: #6200ea;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background: #4500b5;
}
