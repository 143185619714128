.account-trading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    background: linear-gradient(to right, #6a11cb, #2575fc); /* İsteğe bağlı olarak arka plan rengini değiştirebilirsiniz */
}

.account-trading-container h1 {
    font-size: 2rem;
    color: #f9f9f9; /* İsteğe bağlı olarak yazı rengini değiştirebilirsiniz */
}
