/* Navbar styles */
.navbar {
  background: #6a0dad; /* Mor renk */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbar-logo {
  color: white;
  text-decoration: none;
  font-size: 24px;
  margin-right: auto; /* Logoyu sola kaydırmak için */
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.nav-item {
  margin: 0 20px;
}

.nav-links {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.nav-links:hover {
  color: #ddd;
}

.nav-login .login-button {
  background-color: #ffffff; /* Login butonunun arka plan rengi */
  color: #6a0dad; /* Login butonunun yazı rengi */
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.nav-login .login-button:hover {
  background-color: #ddd; /* Hover sırasında login butonunun arka plan rengi */
  color: #6a0dad; /* Hover sırasında login butonunun yazı rengi */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background: #6a0dad;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.dropbtn:hover,
.dropbtn:focus {
  background: #8a2be2;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Responsive Styles */
.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    background: #6a0dad;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    display: none;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-links {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    color: white;
    font-size: 24px;
    background: #6a0dad; /* Mor renk */
    padding: 10px;
    border-radius: 5px;
  }
}
