.coaching-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 100px 0 50px;
    text-align: center;
    background: linear-gradient(to right, #6a11cb, #2575fc);
}

.coaching-header {
    background-color: #6a0dad;
    color: white;
    padding: 50px 20px;
    border-radius: 8px;
    margin-bottom: 30px;
}

.coaching-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.coaching-header p {
    font-size: 1.2rem;
}

.coaching-services {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service {
    background: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 30%;
    transition: transform 0.3s ease;
}

.service:hover {
    transform: scale(1.05);
}

.service img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
}

.service h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.service button {
    background-color: #6a0dad;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.service button:hover {
    background-color: #8a2be2;
}
