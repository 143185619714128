.hero-container {
    background: url('../assets/img/heroSection-bg.webp') center center/cover no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
}

.hero-container h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
}

.hero-container p {
    font-size: 2rem;
}
